export const state = () => ({
  lang: null
})

export const mutations = {
  updateLang(state, value) {
    state.lang = value
  }
}

export const actions = {
  setLang({ commit }, lang) {
    commit('updateLang', lang)
  }
}
