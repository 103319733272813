import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateLang } = createNamespacedHelpers('lang')

export default {
  computed: {
    ...mapStateLang(['lang']),
    isMobile () {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return (
        userAgent.includes('iphone') ||
        userAgent.includes('ipad') ||
        userAgent.includes('android') ||
        userAgent.includes('mobile')
      )
    },
    isiPad () {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return (
        userAgent.includes('ipad') ||
        userAgent.indexOf('ipad') > -1 ||
        (userAgent.indexOf('macintosh') > -1 && 'ontouchend' in document)
      )
    },
    isSafari () {
      const userAgent = window.navigator.userAgent.toLowerCase()
      return (userAgent.indexOf('safari') > -1) && (userAgent.indexOf('chrome') === -1)
    }
  },
  data () {
    return {
      pallete: {
        $active_color: '#1ED6C2',
        $deactive_color: '#757575',
        $tertiary_bg_color: '#f7f7f7',
        $primary_text_color: '#2a2a2a',
        $primary_bg_color: '#fff',
        $secondary_text_color: '#757575',
        $secondary_bg_color: '#e0e0e0',
        $secondary_active_color: '#757575',
        $dark_primary_bg_color: '#000',
        $overlay_color: 'rgba(0, 0, 0, 0.7)',
        $safe_color: '#1976d2',
        $green_color: '#1DC1AC',
        $favorite_color: '#FBC02D',
        $like_color: '#ff5252',
        $gray_color: '#999'
      },
      assetTypes: [
        { type: 'actor', name: '俳優', icon: 'mdi-human' },
        { type: '3d_stage', name: '3Dステージ', icon: 'mdi-bank' },
        { type: '3d_object', name: '3Dオブジェ', icon: 'mdi-cube-outline' },
        { type: 'effect_background', name: '効果背景', icon: 'mdi-flare' },
        { type: 'onomatopoeia', name: 'オノマトペ', icon: 'mdi-exclamation-thick' },
        { type: 'manpu', name: '漫符', icon: 'mdi-sleep' },
        { type: 'background', name: '背景画', icon: 'mdi-image-area' },
        { type: 'image_object', name: '画像オブジェ', icon: 'mdi-image' }
      ],
      hrefForm: '/form/betatester',
      hrefNote: 'https://note.com/comicaroid/',
      hrefTwitter: 'https://twitter.com/comicaroid_',
      hrefYoutube: 'https://www.youtube.com/channel/UCmVwpsf0u9Hy6NF3GIXnzmQ',
      hrefTiktok: 'https://www.tiktok.com/@comicaroid',
      hrefDiscord: 'http://kooh.me'
    }
  },
  methods: {
    // util
    sleep (msec) {
      return new Promise(resolve => setTimeout(resolve, msec))
    },
    numStr (num) {
      if (num >= 1000) return `${num/1000}K`
      else if (num >= 1000000) return `${num/1000000}M`
      else return num
    },
    // getImgUrl (pic) {
    //   return require(pic)
    //   // return require('@/assets/' + pic)
    // },
    // dateToStr (date) {
    //   if (isToday(date)) {
    //     return format(date, 'HH:mm')
    //   } else if (isThisYear(date)) {
    //     return format(date, 'MM/dd')
    //   } else {
    //     return format(date, 'yyyy/MM/dd')
    //   }
    // },
    truncateString (str, num) {
      if (str.length <= num) {
        return str;
      } else {
        return str.slice(0, num > 3 ? num - 3 : num) + '...';
      }
    }
  }
}