<template lang="pug">
v-app
  v-content
    router-view
</template>

<style lang="scss">
@import "./plugins/meltline.css";
@import "@/scss/_variables.scss";
@import "@/scss/common.scss";

html, body {
  margin: 0;
  // height: 100vh;
  // font-family: $gennokaku_gothic !important;
}

.v-application {
  font-family: $gennokaku_gothic !important;
}

.v-application--wrap {
  min-height: -webkit-fill-available;
}

span, p, a, textarea, textarea::placeholder {
  // font-size: 14px;
  font-feature-settings: 'palt';
  letter-spacing: 0.04em;
  color: $primary_text_color;
}

a {
  color: $active_color !important;
}

// スクロールバーを全て削除
* {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display:none;
  }
}
</style>

<script>

export default {
  name: 'App',
  mounted () {
    this.appHeight()
    window.addEventListener('resize', this.appHeight)
  },
  destroyed () {
    window.removeEventListener('resize', this.appHeight)
  },
  methods: {
    appHeight () {
      const doc = document.documentElement
      doc.style.setProperty('--vh', (window.innerHeight*.01) + 'px')
    }
  }
}
</script>
